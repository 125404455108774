import React from "react";
import Col from "react-bootstrap/Col";
import "./PendingSurveyEvaluationPeopleList.css";
import PendingSurveyPerson from "./PendingSurveyPerson";

function PendingSurveyEvaluationPeopleList({ people, toggleSurveyEvaluation }) {
  return (
    <Col className="pending-survey-evaluation-list">
      {people.map((item) => (
        <PendingSurveyPerson
          key={item._id}
          id={item._id}
          firstName={item.firstName}
          lastName={item.lastName}
          status={item.status}
          toggleSurveyEvaluation={toggleSurveyEvaluation}
        />
      ))}
    </Col>
  );
}

export default PendingSurveyEvaluationPeopleList;
