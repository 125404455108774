import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MyTrainingsList from "../Components/MyTrainingsList";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./MyTrainings.css";

function MyTrainings() {
  const [trainings, setTrainings] = useState([]);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useContext(AuthContext);

  let { uid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/user/trainings/${uid}`,
        data: { language: state.language },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setTrainings(response.data.trainings);
      setName(response.data.userName);
    }
    fetch();
    setIsLoading(false);
  }, [state.language, state.token, uid]);

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={9} className="mytrainings">
            <h2>
              {t("Capacitaciones.MisCapacitaciones.Titulo")} - {name}
            </h2>
          </Col>
          <Col lg={3} className="right trainings-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to={`/trainings/user/assignment/${uid}`}
                >
                  <FontAwesomeIcon icon={faChalkboardTeacher} /> Asignar
                  actividad
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          {trainings.length === 0 ? (
            <Col className="empty-trainings center">
              <h5>{t("Capacitaciones.MisCapacitaciones.SinCapacitaciones")}</h5>
            </Col>
          ) : (
            <MyTrainingsList
              trainings={trainings}
              userId={uid}
              stateUserId={state.userId}
              role={state.role}
              language={state.language}
            />
          )}
        </Row>
      </Container>
    );
  }
}

export default MyTrainings;
