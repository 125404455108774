import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./MainContent.css";

import Accounts from "../../Accounts/Pages/Accounts";

import NewAccountForm from "../../Accounts/Components/NewAccountForm";

import EditAccountForm from "../../Accounts/Components/EditAccountForm";

import DeleteAccount from "../../Accounts/Components/DeleteAccount";

import Organizations from "../../Organizations/Pages/Organizations";

import NewOrganizationForm from "../../Organizations/Components/NewOrganizationForm";

import EditOrganizationForm from "../../Organizations/Components/EditOrganizationForm";

import DeleteOrganization from "../../Organizations/Components/DeleteOrganization";

import Categories from "../../Categories/Pages/Categories";

import NewCategoryForm from "../../Categories/Components/NewCategoryForm";

import EditCategoryForm from "../../Categories/Components/EditCategoryForm";

import DeleteCategory from "../../Categories/Components/DeleteCategory";

import Competencies from "../../Competencies/Pages/Competencies";

import Behaviors from "../../Competencies/Pages/Behaviors";

import Scales from "../../Competencies/Pages/Scales";

import NewCompetencyForm from "../../Competencies/Components/NewCompetencyForm";

import EditCompetencyForm from "../../Competencies/Components/EditCompetencyForm";

import DeleteCompetency from "../../Competencies/Components/DeleteCompetency";

import NewBehaviorForm from "../../Competencies/Components/NewBehaviorForm";

import EditBehaviorForm from "../../Competencies/Components/EditBehaviorForm";

import DeleteBehavior from "../../Competencies/Components/DeleteBehavior";

import NewScaleForm from "../../Competencies/Components/NewScaleForm";

import EditScaleForm from "../../Competencies/Components/EditScaleForm";

import DeleteScale from "../../Competencies/Components/DeleteScale";

import Profiles from "../../Profiles/Pages/Profiles";

import NewProfileForm from "../../Profiles/Components/NewProfileForm";

import EditProfileForm from "../../Profiles/Components/EditProfileForm";

import DeleteProfile from "../../Profiles/Components/DeleteProfile";

import ProfileSuccesors from "../../Profiles/Pages/ProfileSuccesors";

import CategoryAssignment from "../../Categories/Components/CategoryAssignment";

import People from "../../People/Pages/People";

import NewPersonForm from "../../People/Components/NewPersonForm";

import EditPersonForm from "../../People/Components/EditPersonForm";

import DeletePerson from "../../People/Components/DeletePerson";

import Reports from "../../Reports/Pages/Reports";

import NewReportForm from "../../Reports/Components/NewReportForm";

import EditReportForm from "../../Reports/Components/EditReportForm";

import DeleteReport from "../../Reports/Components/DeleteReport";

import Evaluations from "../../Evaluations/Pages/Evaluations";

import Rules from "../../Evaluations/Pages/Rules";

import NewRuleForm from "../../Evaluations/Components/NewRuleForm";

import EditRuleForm from "../../Evaluations/Components/EditRuleForm";

import DeleteRule from "../../Evaluations/Components/DeleteRule";

import PeopleEvaluated from "../../Evaluations/Pages/PeopleEvaluated";

import NewEvaluationForm from "../../Evaluations/Components/NewEvaluationForm";

import EditEvaluationForm from "../../Evaluations/Components/EditEvaluationForm";

import DeleteEvaluation from "../../Evaluations/Components/DeleteEvaluation";

import CloneEvaluation from "../../Evaluations/Components/CloneEvaluation";

import Questionnaires from "../../Questionnaires/Pages/Questionnaires";

import OptionSets from "../../Questionnaires/Pages/OptionSets";

import DeleteOptionSet from "../../Questionnaires/Components/DeleteOptionSet";

import NewQuestionnaireForm from "../../Questionnaires/Components/NewQuestionnaireForm";

import EditQuestionnaireForm from "../../Questionnaires/Components/EditQuestionnaireForm";

import DeleteQuestionnaire from "../../Questionnaires/Components/DeleteQuestionnaire";

import Questionnaire from "../../Questionnaires/Components/Questionnaire";

import NewOptionSetForm from "../../Questionnaires/Components/NewOptionSetForm";

import EditOptionSetForm from "../../Questionnaires/Components/EditOptionSetForm";

import OrgChart from "../../OrgChart/Pages/OrgChart";

import VerticalOrgChart from "../../OrgChart/Pages/VerticalOrgChart";

import Dashboard from "../../Dashboard/Pages/Dashboard";

import PersonOverview from "../../People/Pages/PersonOverview";

import ReportPeople from "../../Reports/Pages/ReportPeople";

import ReportDetails from "../../Reports/Components/ReportDetails";

import ComparisonReport from "../../Reports/Components/ComparisonReport";

import NineBoxReport from "../../Reports/Components/NineBoxReport";

import TalentMap from "../../Reports/Components/TalentMap";

import RankingReport from "../../Reports/Components/RankingReport";

import HobbiesReport from "../../Reports/Components/HobbiesReport";

import SurveyReport from "../../Reports/Components/SurveyReport";

import CompetencyEvaluation from "../../Client/Pages/CompetencyEvaluation";

import ObjectiveEvaluation from "../../Client/Pages/ObjectivesEvaluation";

import WellnessEvaluation from "../../Client/Pages/WellnessEvaluation";

import PendingEvaluation from "../../Evaluations/Pages/PendingEvaluation";

import Trainings from "../../Trainings/Pages/Trainings";

import NewTrainingForm from "../../Trainings/Components/NewTrainingForm";

import EditTrainingForm from "../../Trainings/Components/EditTrainingForm";

import DeleteTraining from "../../Trainings/Components/DeleteTraining";

import MyTrainings from "../../Trainings/Pages/MyTrainings";

import NewMyTrainingForm from "../../Trainings/Components/NewMyTrainingForm";

import EditMyTrainingForm from "../../Trainings/Components/EditMyTrainingForm";

import TrainingMap from "../../Trainings/Pages/TrainingMap";

import MyTrainingDetails from "../../Trainings/Pages/MyTrainingDetails";

import Commitments from "../../Commitments/Pages/Commitments";

import MyCommitments from "../../Commitments/Pages/MyCommitments";

import NewCommitmentForm from "../../Commitments/Components/NewCommitmentForm";

import CommitmentDetails from "../../Commitments/Pages/CommitmentDetails";

import EditCommitmentForm from "../../Commitments/Components/EditCommitmentForm";

import DeleteCommitment from "../../Commitments/Components/DeleteCommitment";

import MyProfile from "../../MyProfile/Pages/MyProfile";

import TrainingGroups from "../../Trainings/Pages/TrainingGroups";

import NewTrainingGroupForm from "../../Trainings/Components/NewTrainingGroupForm";

import EditTrainingGroupForm from "../../Trainings/Components/EditTrainingGroupForm";

import DeleteTrainingGroup from "../../Trainings/Components/DeleteTrainingGroup";

import TrainingsStatus from "../../Trainings/Pages/TrainingsStatus";

import PeopleWillEvaluate from "../../Evaluations/Pages/PeopleWillEvaluate";

import PeopleWillEvaluateDetails from "../../Evaluations/Pages/PeopleWillEvaluateDetails";

import PeopleEvaluatedDetails from "../../Evaluations/Pages/PeopleEvaluatedDetails";

import ObjectivesEvaluationMap from "../../Evaluations/Pages/ObjectivesEvaluationMap";

import TrainingAssignment from "../../Trainings/Components/TrainingAssignment";

import UserTrainingAssignment from "../../Trainings/Pages/UserTrainingAssignment";

import ImportPeople from "../../People/Pages/ImportPeople";

import Hobbies from "../../Hobbies/Pages/Hobbies";

import NewHobbyForm from "../../Hobbies/Components/NewHobbyForm";

import EditHobbyForm from "../../Hobbies/Components/EditHobbyForm";

import DeleteHobby from "../../Hobbies/Components/DeleteHobby";

import ChangePassword from "../../MyProfile/Pages/ChangePassword";

import PdfReport from "../../Reports/Components/PdfReport";

import PersonCategories from "../../People/Pages/PersonCategories";

import { Row, Col } from "react-bootstrap";
function MainContent() {
  return (
    <div className="main-content">
      <Row>
        <Col>
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route path="/" element={<Dashboard />} key={Date.now()} />
              <Route
                path="/dashboard"
                element={<Dashboard />}
                key={Date.now()}
              />
              <Route path="/my-profile" element={<MyProfile />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/accounts" element={<Accounts />} />
              <Route path="/accounts/new" element={<NewAccountForm />} />
              <Route path="/accounts/edit/:aid" element={<EditAccountForm />} />
              <Route path="/accounts/delete/:aid" element={<DeleteAccount />} />
              <Route path="/organizations" element={<Organizations />} />
              <Route
                path="/organizations/new"
                element={<NewOrganizationForm />}
              />
              <Route
                path="/organizations/edit/:oid"
                element={<EditOrganizationForm />}
              />
              <Route
                path="/organizations/delete/:oid"
                element={<DeleteOrganization />}
              />
              <Route path="/categories" element={<Categories />} />
              <Route path="/categories/new" element={<NewCategoryForm />} />
              <Route path="/categories/:cid" element={<CategoryAssignment />} />
              <Route
                path="/categories/delete/:cid"
                element={<DeleteCategory />}
              />
              <Route
                path="/categories/edit/:cid"
                element={<EditCategoryForm />}
              />
              <Route
                path="/behaviors/competencies"
                element={<Competencies />}
              />
              <Route
                path="/behaviors/competencies/new"
                element={<NewCompetencyForm />}
              />
              <Route
                path="/behaviors/competencies/edit/:cid"
                element={<EditCompetencyForm />}
              />
              <Route
                path="/behaviors/competencies/delete/:cid"
                element={<DeleteCompetency />}
              />
              <Route path="/behaviors" element={<Behaviors />} />
              <Route path="/behaviors/new" element={<NewBehaviorForm />} />
              <Route
                path="/behaviors/edit/:bid"
                element={<EditBehaviorForm />}
              />
              <Route
                path="/behaviors/delete/:bid"
                element={<DeleteBehavior />}
              />
              <Route
                path="/behaviors/competencies/scales"
                element={<Scales />}
              />
              <Route
                path="/behaviors/competencies/scales/new"
                element={<NewScaleForm />}
              />
              <Route
                path="/behaviors/competencies/scales/edit/:sid"
                element={<EditScaleForm />}
              />
              <Route
                path="/behaviors/competencies/scales/delete/:sid"
                element={<DeleteScale />}
              />
              <Route path="/trainings" element={<Trainings />} />
              <Route
                path="/trainings/training-groups"
                element={<TrainingGroups />}
              />
              <Route
                path="/trainings/training-group/new"
                element={<NewTrainingGroupForm />}
              />
              <Route
                path="/trainings/training-groups/edit/:tid"
                element={<EditTrainingGroupForm />}
              />
              <Route
                path="/trainings/training-groups/delete/:tid"
                element={<DeleteTrainingGroup />}
              />
              <Route path="/trainings/new" element={<NewTrainingForm />} />
              <Route
                path="/trainings/edit/:tid"
                element={<EditTrainingForm />}
              />
              <Route
                path="/trainings/delete/:tid"
                element={<DeleteTraining />}
              />
              <Route
                path="/trainings/assignment/:tid"
                element={<TrainingAssignment />}
              />
              <Route
                path="/trainings/user/assignment/:pid"
                element={<UserTrainingAssignment />}
              />
              <Route
                path="/trainings/details/:tid/:uid"
                element={<MyTrainingDetails />}
              />
              <Route
                path="/trainings/training-status"
                element={<TrainingsStatus />}
              />
              <Route path="/mytrainings/:uid" element={<MyTrainings />} />
              <Route path="/mytrainings/new" element={<NewMyTrainingForm />} />
              <Route
                path="/mytraining/edit/:tid/:uid"
                element={<EditMyTrainingForm />}
              />
              <Route path="/trainings/map" element={<TrainingMap />} />
              <Route path="/profiles" element={<Profiles />} />
              <Route path="/profiles/new" element={<NewProfileForm />} />
              <Route path="/profiles/edit/:pid" element={<EditProfileForm />} />
              <Route path="/profiles/delete/:pid" element={<DeleteProfile />} />
              <Route
                path="/profiles/succesors/:pid"
                element={<ProfileSuccesors />}
              />
              <Route path="/people" element={<People />} />
              <Route path="/people/new" element={<NewPersonForm />} />
              <Route path="/people/edit/:pid" element={<EditPersonForm />} />
              <Route
                path="/people/edit/categories/:pid"
                element={<PersonCategories />}
              />
              <Route path="/people/delete/:pid" element={<DeletePerson />} />
              <Route
                path="/people/overview/:pid"
                element={<PersonOverview />}
              />
              <Route path="/people/import" element={<ImportPeople />} />
              <Route path="/report/pdf" element={<PdfReport />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/reports/new" element={<NewReportForm />} />
              <Route path="/reports/edit/:rid" element={<EditReportForm />} />
              <Route path="/reports/delete/:rid" element={<DeleteReport />} />
              <Route
                path="/reports/comparison/:rid"
                element={<ComparisonReport />}
              />
              <Route path="/reports/:rid" element={<ReportPeople />} />
              <Route path="/reports/map/:rid" element={<TalentMap />} />
              <Route path="/reports/ranking/:rid" element={<RankingReport />} />
              <Route path="/reports/hobbies/:rid" element={<HobbiesReport />} />
              <Route path="/reports/survey/:rid" element={<SurveyReport />} />
              <Route path="/reports/ninebox/:rid" element={<NineBoxReport />} />
              <Route
                path="/reports/:rid/person/:pid"
                element={<ReportDetails />}
              />
              <Route path="/evaluations/rules/:eid" element={<Rules />} />
              <Route
                path="/evaluations/rules/new/:eid"
                element={<NewRuleForm />}
              />
              <Route
                path="/evaluations/rules/edit/:rid"
                element={<EditRuleForm />}
              />
              <Route
                path="/evaluations/rules/delete/:rid"
                element={<DeleteRule />}
              />
              <Route
                path="/pending/evaluation/:eid"
                element={<PendingEvaluation />}
              />
              <Route path="/evaluations" element={<Evaluations />} />
              <Route path="/evaluations/new" element={<NewEvaluationForm />} />
              <Route
                path="/evaluations/edit/:eid"
                element={<EditEvaluationForm />}
              />
              <Route
                path="/evaluations/delete/:eid"
                element={<DeleteEvaluation />}
              />
              <Route
                path="/evaluations/clone/:eid"
                element={<CloneEvaluation />}
              />
              <Route
                path="/evaluation/:eid/people"
                element={<PeopleEvaluated />}
              />
              <Route
                path="/evaluation/:eid/person/:pid"
                element={<PeopleEvaluatedDetails />}
              />
              <Route
                path="/evaluation/:eid/altpeople"
                element={<PeopleWillEvaluate />}
              />
              <Route
                path="/evaluation/:eid/altperson/:pid"
                element={<PeopleWillEvaluateDetails />}
              />
              <Route path="/questionnaires" element={<Questionnaires />} />
              <Route
                path="/questionnaires/new"
                element={<NewQuestionnaireForm />}
              />
              <Route path="/questionnaire/:qid" element={<Questionnaire />} />
              <Route
                path="/questionnaires/edit/:qid"
                element={<EditQuestionnaireForm />}
              />
              <Route
                path="/questionnaires/delete/:qid"
                element={<DeleteQuestionnaire />}
              />
              <Route
                path="/questionnaires/optionsets/new"
                element={<NewOptionSetForm />}
              />
              <Route
                path="/questionnaires/optionsets/edit/:oid"
                element={<EditOptionSetForm />}
              />
              <Route
                path="/questionnaires/optionsets/delete/:oid"
                element={<DeleteOptionSet />}
              />
              <Route
                path="/questionnaires/optionsets"
                element={<OptionSets />}
              />
              <Route path="/commitments" element={<Commitments />} />
              <Route path="/mycommitments" element={<MyCommitments />} />
              <Route path="/commitments/new" element={<NewCommitmentForm />} />
              <Route
                path="/commitments/edit/:cid"
                element={<EditCommitmentForm />}
              />
              <Route
                path="/commitments/delete/:cid"
                element={<DeleteCommitment />}
              />
              <Route
                path="/commitments/details/:cid"
                element={<CommitmentDetails />}
              />
              <Route path="/orgchart" element={<OrgChart />} />
              <Route path="/vertOrgChart" element={<VerticalOrgChart />} />
              <Route
                path="/competency/evaluation/:eid/:pid"
                element={<CompetencyEvaluation />}
              />
              <Route
                path="/objectives/evaluation/:eid"
                element={<ObjectiveEvaluation />}
              />
              <Route
                path="/wellness/evaluation/:eid"
                element={<WellnessEvaluation />}
              />
              <Route
                path="/reports/objectives/map/:rid"
                element={<ObjectivesEvaluationMap />}
              />

              <Route path="/hobbies" element={<Hobbies />} />
              <Route path="/hobbies/new" element={<NewHobbyForm />} />

              <Route path="/hobbies/edit/:hid" element={<EditHobbyForm />} />
              <Route path="/hobbies/delete/:hid" element={<DeleteHobby />} />
            </Routes>
          </Suspense>
        </Col>
      </Row>
    </div>
  );
}

export default MainContent;
