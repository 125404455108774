import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./UserTrainingAssignment.css";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function UserTrainingAssignment() {
  const [selectedTrainings, setSelectedTrainings] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [completeTrainings, setCompleteTrainings] = useState([]);
  const [userName, setUserName] = useState("");
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  let { pid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/trainings/user/assignment/trainings`,
        data: {
          userId: pid,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setUserName(
        response.data.user.firstName + " " + response.data.user.lastName
      );
      setTrainings(response.data.trainings);
      setCompleteTrainings(response.data.trainings);
      setSelectedTrainings(response.data.selectedTrainings);
      setGroups(response.data.trainingGroups);
    }
    fetch();
    setIsLoading(false);
  }, [state.token, pid]);

  const handlePoolChange = async (e) => {
    const difference = completeTrainings.filter(
      ({ _id: id1 }) => !selectedTrainings.some(({ _id: id2 }) => id2 === id1)
    );

    if (e.target.value !== "") {
      const newTrainings = difference.filter(
        (item) => item.trainingGroup === e.target.value
      );
      setTrainings(newTrainings);
    } else {
      setTrainings(difference);
    }
  };

  const handleSelection = (id) => {
    let buffer = trainings.find((element) => element._id === id);

    let newTrainingsArray = trainings.filter((element) => {
      return element._id !== id;
    });

    setSelectedTrainings([...selectedTrainings, buffer]);
    setTrainings(newTrainingsArray);
  };

  const handleBackToPool = (id) => {
    let buffer = selectedTrainings.find((element) => element._id === id);

    let newSelectedTrainingsArray = selectedTrainings.filter((element) => {
      return element._id !== id;
    });

    setTrainings([...trainings, buffer]);
    setSelectedTrainings(newSelectedTrainingsArray);
  };

  const handleSubmit = async () => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/trainings/users/single/assign`,
      data: {
        selectedTrainings: selectedTrainings,
        userId: pid,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate(`/mytrainings/${pid}`);
  };

  if (isLoading) {
    return (
      <div className="loading">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col lg={9}>
            <Form>
              <Col className="header">
                <h5>{userName}</h5>
              </Col>
              <Col className="selected-people">
                {selectedTrainings.map((element) => (
                  <Container key={element._id} fluid className="person">
                    <Row className="align-items-center">
                      <Col lg={11}>
                        <Col>
                          <h5>{element.name}</h5>
                          <p>{element.trainingGroupName}</p>
                        </Col>
                      </Col>
                      <Col lg={1} className="center">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          onClick={() => handleBackToPool(element._id)}
                        />
                      </Col>
                    </Row>
                  </Container>
                ))}
              </Col>
              <Col className="category-buttons">
                <Button className="primary" onClick={handleSubmit}>
                  {t("Capacitaciones.AsignacionCapacitaciones.Guardar")}
                </Button>
                <Link to={`/mytrainings/${pid}`}>
                  <Button className="danger">
                    {t("Capacitaciones.AsignacionCapacitaciones.Cancelar")}
                  </Button>
                </Link>
              </Col>
            </Form>
          </Col>
          <Col lg={3} className="people-pool">
            <Col>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>
                  {t("Capacitaciones.AsignacionCapacitaciones.FiltrarGrupos")}
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={handlePoolChange}
                >
                  <option value="">
                    {t(
                      "Capacitaciones.AsignacionCapacitaciones.SeleccionarGrupo"
                    )}
                  </option>
                  {groups.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              {trainings.map((element) => (
                <Row
                  key={element._id}
                  className="align-items-center people-pool-item"
                >
                  <Col lg={2}>
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      onClick={() => handleSelection(element._id)}
                    />
                  </Col>
                  <Col lg={10} className="right">
                    <Col>
                      <h5>{element.name}</h5>
                      <p>{element.trainingGroupName}</p>
                    </Col>
                  </Col>
                </Row>
              ))}
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default UserTrainingAssignment;
