import React from "react";
import { Col } from "react-bootstrap";
import parse from "html-react-parser";
import "./OpenQuestionSummary.css";

function OpenQuestionSummary({ description, summary }) {
  const cleanText = (text) => {
    let filteredText = text.replace(/```/g, "").replace(/html/g, "");
    return filteredText;
  };

  return (
    <Col className="open-question-summary mb-3">
      <Col className="center">
        <h5>{description}</h5>
      </Col>
      <Col>{parse(cleanText(summary))}</Col>
    </Col>
  );
}

export default OpenQuestionSummary;
